import React, { useEffect } from "react";
import JobList from "../../global/JobList/JobList";
import JobsTeaser from "../../global/JobsTeaser/index";
import { connect } from "react-redux";
import {
  getBoosting,
  getLastVisit,
  getSearchHistoryInitialized,
} from "../../../reducer";
import { loadHistory } from "../../../operations/searchHistory";
import { fetchJobSearch, loadLastVisit } from "../../../operations/search";
import { setLastVisit } from "../../../actions";
import SearchConfigFactoryLib from "../../../libs/SearchConfigFactoryLib";
import { ONPAGE_NAVIGATON_EVENTS } from "../../../../cms_content/components/global/OnPageNavigation/OnPageNavigation";
import useJobs from "../../../hooks/useJobs";
import ButtonToSearchWithConfig from "../../global/ButtonToSearchWithConfig";
import GeneralUtility from "../../../../../libs/GeneralUtility";

const JobsWidget = ({
  ceId,
  layout = 0,
  employer = {},
  itemCount = 10,
  moreJobsButtonText,
  showNewJobsCount = false,
  disableBoosting = 0,
  boosting,
  filterActivities = [],
  filterCareerlevels = [],
  filterCities = [],
  filterFederalStates = [],
  filterTopJobs = 0,
  filterStartpageTopJobs = 0,
  professionalGroup,
  adjustToSearchHistory = true,
  searchHistoryInitialized,
  loadHistory,
  lastVisit,
  loadLastVisit,
  fetchJobSearch,
  showInOnPageNavigation,
  hideParentContainerWhenNoJobsFound,
}) => {
  const { uid: employerUid = 0, jobflatrate: employerJobflatrate = 0 } =
    employer;

  if (!moreJobsButtonText || moreJobsButtonText === "") {
    moreJobsButtonText = "Alle Jobs";
  }

  // check if layout = list view
  if ([0, 101].includes(parseInt(layout)) && !GeneralUtility.isServerSide()) {
    const headerHeight =
      document.getElementById("on-page-nav")?.offsetHeight || 0;
    const elementHeaderHeight = 85;
    const elementFooterButtonHeight = 50;
    const availableHeight =
      document.documentElement.clientHeight -
      headerHeight -
      elementHeaderHeight -
      elementFooterButtonHeight;
    const possibleItemCount = Math.floor(availableHeight / 172);

    if (possibleItemCount <= 0) {
      itemCount = 1;
    } else if (possibleItemCount < itemCount) {
      itemCount = possibleItemCount;
    }
  }

  useEffect(() => {
    // generate boosting (if not already generated)
    if (!searchHistoryInitialized) {
      loadHistory();
    }

    // register on page navigation changed listener to add current job count to new elements
    if (parseInt(showInOnPageNavigation)) {
      const eventListener = () => {
        processOnPageNavigationAdjustments();
      };
      document.addEventListener(ONPAGE_NAVIGATON_EVENTS.REFRESH, eventListener);

      return () => {
        document.removeEventListener(
          ONPAGE_NAVIGATON_EVENTS.REFRESH,
          eventListener
        );
      };
    }
  }, []);

  let searchConfig = SearchConfigFactoryLib.setSorting();
  searchConfig = SearchConfigFactoryLib.setLimit(itemCount, searchConfig);

  if (disableBoosting || boosting.length === 0) {
    if (employerUid) {
      searchConfig = SearchConfigFactoryLib.setEmployerFilter(
        employerUid,
        searchConfig
      );
    }

    if (filterActivities) {
      searchConfig = SearchConfigFactoryLib.setActivityFilter(
        filterActivities,
        searchConfig
      );
    }

    if (parseInt(filterTopJobs) === 1) {
      searchConfig = SearchConfigFactoryLib.setTopJobFilter(
        filterTopJobs,
        searchConfig
      );
    }

    if (parseInt(filterStartpageTopJobs) === 1) {
      searchConfig = SearchConfigFactoryLib.setStartpageTopJobFilter(
        filterTopJobs,
        searchConfig
      );
    }

    if (filterCareerlevels) {
      searchConfig = SearchConfigFactoryLib.setCareerLevelFilter(
        filterCareerlevels,
        searchConfig
      );
    }

    if (filterCities) {
      searchConfig = SearchConfigFactoryLib.setLocationFilter(
        filterCities,
        searchConfig
      );
    }

    if (filterFederalStates) {
      searchConfig = SearchConfigFactoryLib.setFederalStateFilter(
        filterFederalStates,
        searchConfig
      );
    }
  }

  const {
    data: { jobs, jobCount },
    error,
    mutate,
  } = useJobs(searchConfig, itemCount, {
    boosting: {
      disable: disableBoosting,
      config: boosting,
    },
    renderDummyRecords: parseInt(layout) === 100,
  });

  useEffect(() => {
    searchHistoryInitialized && mutate();
  }, [searchHistoryInitialized]);

  /**
   * Add current job count to on page navigation item
   */
  const processOnPageNavigationAdjustments = () => {
    if (jobCount > 0) {
      // check if item is included in on page navigation
      const onPageNavItem = document.getElementById(`onpage-link-${ceId}`);
      if (onPageNavItem && onPageNavItem.dataset.title) {
        // if true => add job count to on page navigation title
        onPageNavItem.parentNode.classList.remove("hide");
        onPageNavItem.text = `${onPageNavItem.dataset.title} (${jobCount})`;
      }
    } else {
      const onPageNavItem = document.getElementById(`onpage-link-${ceId}`);
      if (onPageNavItem) {
        // hide on page navigation item if no jobs found
        onPageNavItem.parentNode.classList.add("hide");
      }
    }
  };

  // Hide job list widgets if no jobs found
  useEffect(() => {
    const ceWrapper = document.getElementById(`c${ceId}`);
    const parentWrapper = ceWrapper.closest(".ce--container");
    if (ceWrapper) {
      if (Array.isArray(jobs) && jobs.length === 0) {
        ceWrapper.classList.add("hide");
        if (parseInt(hideParentContainerWhenNoJobsFound) === 1) {
          parentWrapper.classList.add("hide");
        } else {
          parentWrapper.classList.remove("hide");
        }
      } else {
        ceWrapper.classList.remove("hide");
        parentWrapper.classList.remove("hide");
      }
    }
    if (showInOnPageNavigation) {
      processOnPageNavigationAdjustments();
    }
  }, [jobs]);

  // const jobSearchLink = PagePathUtility.getPathConfig("jobSearch");

  // const redirectToSearchWithConfig = (searchConfig) => {
  //   fetchJobSearch(searchConfig).then(() => {
  //     Router.push(jobSearchLink);
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //     });
  //   });
  // };
  //
  // const ButtonToSearchWithConfig = ({ searchConfig, label }) => (
  //   <>
  //     {!searchConfig || GeneralUtility.isEmptyObject(searchConfig) ? (
  //       <Button
  //         href={PagePathUtility.createLinkWithBasepath("/stellenmarkt")}
  //         classes={`button--black-hollow`}
  //         text="Alle Top Jobs"
  //         icon={true}
  //       />
  //     ) : (
  //       <Button
  //         classes="button--black-hollow"
  //         text={label}
  //         icon={true}
  //         onClick={redirectToSearchWithConfig.bind(this, searchConfig)}
  //       />
  //     )}
  //   </>
  // );

  if (error) return null;
  if (!jobs)
    return (
      <>
        <div>Laden...</div>
      </>
    );

  if (parseInt(layout) === 100) {
    return (
      <JobsTeaser
        jobs={jobs}
        showNewJobsCount={showNewJobsCount}
        loadLastVisit={loadLastVisit}
        lastVisit={lastVisit}
      />
    );
  } else if (parseInt(layout) === 101) {
    return (
      <>
        <JobList jobs={jobs} layout={"extended"} />
        <ButtonToSearchWithConfig label={moreJobsButtonText} />
      </>
    );
  } else {
    return (
      <>
        <JobList jobs={jobs} layout={"small"} />
        {jobs.length >= itemCount && (
          <ButtonToSearchWithConfig
            searchConfig={searchConfig}
            label={moreJobsButtonText}
          />
        )}
      </>
    );
  }
};

const mapStateToProps = (state) => ({
  boosting: getBoosting(state),
  lastVisit: getLastVisit(state),
  searchHistoryInitialized: getSearchHistoryInitialized(state),
});

const mapDispatchToProps = {
  loadHistory,
  loadLastVisit,
  setLastVisit,
  fetchJobSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsWidget);
